import React from 'react';
import { Query } from 'react-apollo';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import Loading from './components/Loading';
import { GET_CURRENT_USER } from './api/user';
import './App.css';

function App() {
  return (
    <Query query={GET_CURRENT_USER}>
      {({ loading, error, data: { currentUser } }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error!</p>;
        if (!currentUser) return <LoginPage />;
        return <HomePage />;
      }}
    </Query>
  );
}

export default App;
