import React from 'react';
import { Avatar, Button, Checkbox } from 'antd';
import dayjs from 'dayjs';
import './index.css';

export default function MessageCard({
  message,
  onPress,
  onSelect,
  showSelector,
  checked,
  showDetails,
  showEdit
}) {
  if (!message) return null;
  const { _id, content, imgUrls, user, createdAt, deleted } = message;
  const { nickname, avatarUrl, gender } = user || {};

  return (
    <div className="message-container" onClick={() => showDetails(_id)}>
      <div className="message-aligner">
        <div
          className="message-selector"
          style={{ display: showSelector ? 'flex' : 'none' }}
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            checked={checked}
            onChange={() => onSelect(_id)}
          />
        </div>
        <div className="message-content">
          <p>
            {deleted && <span className="message-deleted">[消息已删除]</span>}
            {content}
          </p>
          {imgUrls.length > 0 && (
            <div className="message-img-container">
              {!!imgUrls.length && (
                <div style={{ color: 'rgb(68, 179, 146)', fontWeight: '500' }}>
                  《信息有{imgUrls.length}张图片》
                </div>
              )}
            </div>
          )}
          <div className="message-user">
            <Avatar size="small" src={avatarUrl}>
              N/A
            </Avatar>
            <span>{nickname}</span>
            <span
              style={{
                color: gender === 'male' ? '#1C5D99' : '#C71453',
                fontWeight: 'bold'
              }}
            >
              {gender}
            </span>
            <span>{dayjs(createdAt).format('MM-DD HH:mm')}</span>
          </div>
        </div>
      </div>
      { showEdit &&
        <div className="message-buttons" onClick={e => e.stopPropagation()}>
          <Button
            type="primary"
            shape="circle"
            icon="edit"
            className="message-button"
            onClick={() => onPress(_id, 'Edit')}
          />
          <Button
            shape="circle"
            type="danger"
            icon="delete"
            className="message-button"
            onClick={() => onPress(_id, 'Delete')}
          />
        </div>
      }
    </div>
  );
}
