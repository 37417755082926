import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Select, message } from 'antd';
import { Query, withApollo } from 'react-apollo';
import UserCard from '../../components/UserCard';
import UserDetail from '../../components/UserDetail';
import UserForm from '../../components/UserDetail/UserForm';
import {
  GET_USERS,
  UPDATE_USER_DETAIL,
  BAN_USER,
  UN_BAN_USER
} from '../../api/user';
import { Pagination, Empty } from 'antd';
import ModalBox from '../../components/ModalBox';
import Loading from '../../components/Loading';
import './index.css';
const { Option } = Select;

class UserFeed extends Component {
  state = {
    selectedUserId: '',
    operationType: '', //Edit, View or Ban
    currentUserGroup: [],
    componentInfo: {},
    keyword: '',
    searchType: 'nickname'
  };

  _navigationOnchange = pageNum => {
    this.props.history.push('/users/' + pageNum);
  };

  _getSearchTerm = () => {
    const { searchType, keyword } = this.state;
    const { pageNum = 1 } = this.props.match.params;
    return {
      pageSize: keyword ? 100 : 15,
      currentPage: keyword ? 1 : pageNum * 1,
      ...(keyword && {
        searchTerm:
          searchType === '_id' ? { _id: keyword } : { nickname: keyword }
      })
    };
  };

  _handleUpdate = (key, value) => {
    this.setState(({ componentInfo }) => ({
      componentInfo: {
        ...componentInfo,
        [key]: value
      }
    }));
  };

  _handleClick = (_id, type, userGroup = '') => {
    this.setState({
      selectedUserId: _id,
      operationType: type,
      currentUserGroup: userGroup
    });
  };

  _handleConfirm = () => {
    const {
      operationType: type,
      selectedUserId,
      currentUserGroup: userGroup,
      componentInfo
    } = this.state;

    if (type === 'Ban') {
      //ban or unban user
      if (userGroup.length && userGroup[0] === 'banned') {
        this.props.client
          .mutate({
            mutation: UN_BAN_USER,
            variables: { _id: selectedUserId },
            refetchQueries: () => [
              {
                query: GET_USERS,
                variables: this._getSearchTerm()
              }
            ]
          })
          .then(() => message.success('User unbanned!'))
          .catch(err => message.error(err.message || 'Error occurred!'));
      } else {
        this.props.client
          .mutate({
            mutation: BAN_USER,
            variables: {
              _id: selectedUserId,
              date: componentInfo.selectedDate || '2099-09-06'
            },
            refetchQueries: () => [
              {
                query: GET_USERS,
                variables: this._getSearchTerm()
              }
            ]
          })
          .then(() => message.success('User banned!'))
          .catch(err => message.error(err.message || 'Error occurred!'));
      }
      this._closeModal();
    } else if (type === 'Edit') {
      const { form } = this.formRef.props;
      if (!form) return;
      form.validateFields((err, values) => {
        if (err) return;
        //Update user info
        this.props.client
          .mutate({
            mutation: UPDATE_USER_DETAIL,
            variables: { _id: selectedUserId, data: values },
            refetchQueries: () => [
              {
                query: GET_USERS,
                variables: this._getSearchTerm()
              }
            ]
          })
          .then(() => message.success('User updated!'))
          .catch(err => message.error(err.message || 'Error occurred!'));
      });
    }
    this._closeModal();
  };

  _closeModal = () => {
    this.setState({
      selectedUserId: '',
      operationType: '',
      currentUserGroup: [],
      componentInfo: {}
    });
  };

  render() {
    const { keyword, searchType, selectedUserId, operationType } = this.state;
    const { pageNum = 1 } = this.props.match.params;

    return (
      <div className="list-area">
        <div className="user-feed-search-container">
          <input
            type="text"
            maxLength="36"
            placeholder="Search for a user"
            className="user-feed-input"
            onKeyDown={e => {
              if (e.key === 'Enter') this.setState({ keyword: e.target.value });
            }}
          />

          <Select
            onChange={type => this.setState({ searchType: type })}
            defaultValue={searchType}
          >
            <Option value="_id">_id</Option>
            <Option value="nickname">nickname</Option>
          </Select>

          {keyword && (
            <Button
              className="user-feed-search-submit"
              icon="close"
              type="danger"
              shape="circle"
              onClick={() => this.setState({ keyword: '' })}
            />
          )}
        </div>
        <Query query={GET_USERS} variables={this._getSearchTerm()}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <p>Error!</p>;
            const { users = [] } = data;

            return users.length ? (
              <Fragment>
                <p>Found {users.length} matches</p>
                <div className="user-feed-container">
                  {users.map(user => (
                    <UserCard
                      user={user}
                      _handleClick={this._handleClick}
                      key={user._id}
                    />
                  ))}
                </div>
                <Pagination
                  showQuickJumper
                  defaultCurrent={pageNum * 1}
                  total={6000}
                  onChange={this._navigationOnchange}
                />
              </Fragment>
            ) : (
              <Empty className="empty-hint" />
            );
          }}
        </Query>
        <ModalBox
          visible={!!selectedUserId && !!operationType}
          title={`${operationType} user (id: ${selectedUserId})`}
          onConfirm={this._handleConfirm}
          onCancel={this._closeModal}
        >
          <UserDetail
            _id={selectedUserId}
            type={operationType}
            _handleUpdate={this._handleUpdate}
          />
          {operationType === 'Edit' && (
            <div style={{ marginTop: '30px' }}>
              <UserForm
                onSetRef={ref => {
                  this.formRef = ref;
                }}
                onSubmit={this._handleConfirm}
                _id={selectedUserId}
              />
            </div>
          )}
        </ModalBox>
      </div>
    );
  }
}

export default withApollo(withRouter(UserFeed));
