import React, { Component } from 'react';
// import {Button} from 'antd';
import './index.css';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

class CartonCard extends Component {
  render() {
    const { carton = {}, selectCarton } = this.props;
    return (
      <div
        className="carton-card-container"
        onClick={() => selectCarton(carton._id)}
      >
        <div className="carton-card-upper">
          <img className="carton-card-image" src={carton.imgUrl} alt="" />
          {carton.manager && (
            <div className="carton-card-manager">{carton.manager.nickname}</div>
          )}
        </div>
        {carton.school && (
          <div
            className="carton-card-lower"
            style={{ padding: '10px 10px 1px' }}
          >
            <div className="carton-card-school-outer">
              <div className="carton-card-school-inner">
                <div className="carton-card-school-text">{carton.school}</div>
              </div>
            </div>

            <div className="carton-card-school-outer">
              <div className="carton-card-school-inner">
                <div className="carton-card-school-text">{carton.category}</div>
              </div>
            </div>
          </div>
        )}
        <div className="carton-card-lower">
          <div className="carton-card-title">{carton.title}</div>
          <div className="carton-card-desc">{carton.desc}</div>
        </div>
        <div className="carton-card-topics-container">
          {carton.topics.map((topic, index) => (
            <div className="carton-card-topic" key={index}>
              {' '}
              #{topic}#
            </div>
          ))}
          {!!!carton.topics.length && (
            <div className="carton-card-topic-none"> 没有话题</div>
          )}
        </div>
      </div>
    );
  }
}

export default withApollo(withRouter(CartonCard));
