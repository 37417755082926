import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';

class Dir extends Component {
  render() {
    const { pathname } = this.props.location;
    const selectedKey = (pathname !== '/'
      ? pathname.substring(1, pathname.length)
      : 'feed'
    ).split('/');
    return (
      <div>
        <Breadcrumb className="pagePath">
          {selectedKey.map(dir => (
            <Breadcrumb.Item key={selectedKey.indexOf(dir)}>
              <span style={{ textTransform: 'capitalize' }}>{dir}</span>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    );
  }
}
export default withRouter(Dir);
