import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, Button, message, Empty } from 'antd';
import { Query, withApollo } from 'react-apollo';
import CartonCard from '../../components/CartonCard';
import {
  GET_CARTONS,
  CREATE_CARTON,
  UPDATE_CARTON,
  DELETE_CARTON,
  GET_CATEGORIES
} from '../../api/carton';
import ModalBox from '../../components/ModalBox';
import Loading from '../../components/Loading';
import CartonForm from './CartonForm';
import './index.css';
const { Option } = Select;

class CartonList extends Component {
  state = {
    currentSchool: 'All',
    currentCartonId: '',
    keyword: '',
    searchType: 'title',
    cartonIDforDelete: '',
    categories: ['All categories'],
    selectedCategory: 'All categories'
  };

  componentDidMount() {
    this.props.client.query({ query: GET_CATEGORIES }).then(({ data }) => {
      const { cartons = [] } = data;
      this.setState({
        categories: Array.from(
          new Set(
            ['All categories'].concat(cartons.map(({ category }) => category))
          )
        )
      });
    });
  }

  _getSearchTerm = () => {
    const { currentSchool, searchType, keyword, selectedCategory } = this.state;
    return {
      ...(selectedCategory !== 'All categories' && {
        category: selectedCategory
      }),
      ...(currentSchool !== 'All' && { school: currentSchool }),
      ...(keyword && searchType === '_id' && { _id: keyword }),
      ...(keyword && searchType === 'title' && { title: keyword }),
      ...(keyword && searchType === 'desc' && { desc: keyword })
    };
  };

  _handleSelect = _id => this.setState({ currentCartonId: _id });

  _handleDelete = _id => {
    this.props.client
      .mutate({
        mutation: DELETE_CARTON,
        variables: { _id },
        refetchQueries: () => [
          {
            query: GET_CARTONS,
            variables: {
              searchTerm: this._getSearchTerm()
            }
          }
        ]
      })
      .then(() => message.success('Carton deleted!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
    this._closeModal();
  };

  _handleSubmit = () => {
    const { form } = this.formRef.props;
    const { currentCartonId: _id } = this.state;
    const formType = _id === 'NoID' ? 'Create' : 'Edit';
    if (!form) return this._closeModal();
    form.validateFields((err, values) => {
      if (err) return;
      if (formType === 'Create') {
        this.props.client
          .mutate({
            mutation: CREATE_CARTON,
            variables: {
              data: values
            },
            refetchQueries: () => [
              {
                query: GET_CARTONS,
                variables: {
                  searchTerm: this._getSearchTerm()
                }
              }
            ]
          })
          .then(() => message.success('Carton created!'))
          .catch(err => message.error(err.message || 'Error occurred!'));
      } else if (formType === 'Edit') {
        this.props.client
          .mutate({
            mutation: UPDATE_CARTON,
            variables: {
              _id: _id,
              data: values
            },
            refetchQueries: () => [
              {
                query: GET_CARTONS,
                variables: {
                  searchTerm: this._getSearchTerm()
                }
              }
            ]
          })
          .then(() => message.success('Carton updated!'))
          .catch(err => message.error(err.message || 'Error occurred!'));
      }
    });
    this._closeModal();
  };

  _cancelSearch = () => this.setState({ keyword: '' });

  _closeModal = () => this.setState({ currentCartonId: '' });

  render() {
    const {
      keyword,
      searchType,
      selectedCategory,
      categories,
      currentSchool,
      currentCartonId,
      cartonIDforDelete
    } = this.state;

    return (
      <Fragment>
        <div className="message-top-buttons">
          <div className="aligner">
            <input
              placeholder="Search for a carton"
              className="search-input"
              onKeyDown={e => {
                if (e.key === 'Enter') this.setState({ keyword: e.target.value })
              }}
            />

            <Select
              style={{ width: '150px', marginRight: '8px' }}
              onChange={type => this.setState({ searchType: type })}
              defaultValue={searchType}
            >
              <Option value="_id">_id</Option>
              <Option value="title">title</Option>
              <Option value="desc">desc</Option>
            </Select>

            {keyword && (
              <Button
                className="message-top-button"
                icon="close"
                type="danger"
                shape="circle"
                onClick={this._cancelSearch}
              />
            )}
          </div>

          <div className="aligner">
            <Select
              style={{ width: '150px', marginRight: '8px' }}
              onChange={category =>
                this.setState({ selectedCategory: category })
              }
              defaultValue={selectedCategory}
            >
              {categories.map((category, index) => (
                <Option key={index} value={category}>
                  {category}
                </Option>
              ))}
            </Select>

            <Select
              style={{ width: '150px', marginRight: '8px' }}
              onChange={school => this.setState({ currentSchool: school })}
              defaultValue={currentSchool}
            >
              <Option value="All">All schools</Option>
              <Option value="XJTLU">XJTLU</Option>
              <Option value="SUDA">SUDA</Option>
              <Option value="UNNC">UNNC</Option>
            </Select>
          </div>
        </div>

        <Query
          query={GET_CARTONS}
          variables={{ searchTerm: this._getSearchTerm() }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <p>Error!</p>;
            const { cartons = {} } = data;
            return cartons.length ? (
              <Fragment>
                <p>
                  Showing {cartons.length} cartons
                </p>
                <div className="carton-list-container">
                  {cartons.map(carton => (
                    <CartonCard
                      selectCarton={this._handleSelect}
                      carton={carton}
                      key={carton._id}
                    />
                  ))}
                </div>
              </Fragment>
            ) : (
              <Empty className="empty-hint" />
            );
          }}
        </Query>

        <ModalBox
          title={
            currentCartonId === 'NoID'
              ? `Create new carton`
              : `Carton (id: ${currentCartonId})`
          }
          visible={!!currentCartonId}
          onCancel={this._closeModal}
          onConfirm={this._handleSubmit}
        >
          {currentCartonId !== 'NoID' && (
            <div className="carton-list-modal-upper">
              <input
                placeholder="Enter carton id to delete"
                className="search-input"
                value={cartonIDforDelete}
                style={{ width: '60%' }}
                onChange={e =>
                  this.setState({ cartonIDforDelete: e.target.value })
                }
              />

              <Button
                icon="delete"
                type="danger"
                className="carton-list-modal-button"
                disabled={cartonIDforDelete !== currentCartonId}
                onClick={() => this._handleDelete(currentCartonId)}
              >
                Delete Carton
              </Button>
            </div>
          )}
          <CartonForm
            cartonId={currentCartonId}
            onSetRef={ref => {
              this.formRef = ref;
            }}
            onSubmit={this._handleSubmit}
          />
        </ModalBox>

        <Button
          icon="plus"
          shape="circle"
          type="primary"
          size="large"
          className="topic-add-btn"
          onClick={() => this.setState({ currentCartonId: 'NoID' })}
        />
      </Fragment>
    );
  }
}

export default withApollo(withRouter(CartonList));
