import React from 'react';
import { message } from 'antd';
import { Layout, Avatar, Popconfirm } from 'antd';
import { Query, withApollo } from 'react-apollo';
import { GET_CURRENT_USER, LOG_OUT } from '../../api/user';
import './index.css';

const { Header } = Layout;

function NavBar({ client }) {
  const onLogout = () => {
    client.mutate({
      mutation: LOG_OUT,
    }).then(() => {
      message.success('Successfully logged out!');
    });
  };

  return (
    <Query query={GET_CURRENT_USER}>
      {({ data: { currentUser } }) => {
        return (
          <Header className="navbar">
            <Popconfirm
              title="Logout?"
              onConfirm={onLogout}
              okText="Yes"
              cancelText="No"
            >
              <Avatar size="medium" className="user-btn">
                {currentUser && currentUser.nickname && currentUser.nickname[0]}
              </Avatar>
            </Popconfirm>
          </Header>
        );
      }}
    </Query>
  );
}

export default withApollo(NavBar);
