import React, { Component } from 'react';
import { Query, withApollo } from 'react-apollo';
import { Button, message } from 'antd';
import Masonry from 'react-masonry-component';
import {
  GET_BANNERS,
  SET_BANNER,
  CREATE_BANNER,
  DELETE_BANNER,
} from '../../api/banner';
import Banner from '../../components/Banner';
import './index.css';
import ModalBox from '../../components/ModalBox';
import Loading from '../../components/Loading';
// import BannerEditor from '../../components/BannerEditor';
import BannerForm from './BannerForm';

class BannerSetting extends Component {
  state = {
    selectedBanner: null,
    modalVisible: false,
    formMode: null,
  };

  toggleModal = (banner) => {
    this.setState({
      modalVisible: !this.state.modalVisible,
      formMode: banner ? 'update' : 'create',
      selectedBanner: banner,
    });
  };

  onAddBanner = (bannerData) => {
    this.props.client
      .mutate({
        mutation: CREATE_BANNER,
        variables: { bannerData },
        refetchQueries: () => [{ query: GET_BANNERS }],
      })
      .then(() => message.success('Banner created!'))
      .catch((err) => message.error(err.message || 'Error occurred!'));
  };

  onEditBanner = (_id, bannerData) => {
    this.props.client
      .mutate({
        mutation: SET_BANNER,
        variables: { _id, bannerData },
        refetchQueries: () => [{ query: GET_BANNERS }],
      })
      .then(() => message.success('Banner updated!'))
      .catch((err) => message.error(err.message || 'Error occurred!'));
  };

  onDeleteBanner = (_id) => {
    this.props.client
      .mutate({
        mutation: DELETE_BANNER,
        variables: { _id },
        refetchQueries: () => [{ query: GET_BANNERS }],
      })
      .then(() => message.success('Banner deleted!'))
      .catch((err) => message.error(err.message || 'Error occurred!'));
  };

  setRef = (ref) => {
    this.formRef = ref;
  };

  onBannerFormSubmit = () => {
    const { form } = this.formRef.props;
    const { formMode, selectedBanner } = this.state;
    form.validateFields((err, values) => {
      if (!err) {
        if (formMode === 'create') this.onAddBanner(values);
        else if (formMode === 'update')
          this.onEditBanner(selectedBanner._id, values);
        form.resetFields();
        this.toggleModal();
      }
    });
  };

  render() {
    const { selectedBanner, modalVisible } = this.state;
    return (
      <Query query={GET_BANNERS}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <p>Error!</p>;
          return (
            <div>
              <Masonry
                options={{ transitionDuration: 0 }}
                disableImagesLoaded={false}
                updateOnEachImageLoad={true}
              >
                >
                {data.banners.map((banner) => (
                  <Banner
                    key={banner._id}
                    banner={banner}
                    onDelete={this.onDeleteBanner}
                    onEdit={this.toggleModal}
                  />
                ))}
              </Masonry>
              <ModalBox
                visible={modalVisible}
                title="Edit Banner"
                onConfirm={this.onBannerFormSubmit}
                onCancel={() => this.toggleModal()}
              >
                <BannerForm
                  onSubmit={this.onBannerFormSubmit}
                  onSetRef={this.setRef}
                  banner={selectedBanner}
                />
              </ModalBox>
              <Button
                icon="plus"
                shape="circle"
                type="primary"
                size="large"
                className="topic-add-btn"
                onClick={() => this.toggleModal()}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(BannerSetting);
