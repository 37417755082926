import React from 'react';
import SharedForm from '../../components/SharedForm';
import { Query } from 'react-apollo';
import { GET_CARTON_DETAILS } from '../../api/carton';
import { Input, Select } from 'antd';
const { Option } = Select;

export default function CartonForm({ onSubmit, onSetRef, cartonId }) {
  if (!cartonId) return null;
  return (
    <Query query={GET_CARTON_DETAILS} variables={{ _id: cartonId }}>
      {({ loading, error, data }) => {
        if (loading) return <p>loading...</p>;
        if (error && cartonId !== 'NoID') return <p>error!</p>;
        const { title, desc, category, imgUrl, school, manager, topics } =
          (data || {}).carton || {};
        let topicList = new Set([]);
        if (topics) {
          topics.forEach(topic => {
            topicList.add(<Option key={topic}>{topic}</Option>);
          });
        }
        return (
          <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
            {{
              formItems: [
                {
                  title: 'Title',
                  name: 'title',
                  options: {
                    initialValue: title,
                    rules: [
                      { required: true, message: 'Title cannot be empty' }
                    ]
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Description',
                  name: 'desc',
                  options: {
                    initialValue: desc || ''
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Category',
                  name: 'category',
                  options: {
                    initialValue: category
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Image',
                  name: 'imgUrl',
                  options: {
                    initialValue: imgUrl,
                    rules: [
                      { required: true, message: 'Carton needs an image' }
                    ]
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'School',
                  name: 'school',
                  options: {
                    initialValue: school
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Manager ID',
                  name: 'manager',
                  options: {
                    initialValue: (manager || {})._id
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Topics',
                  name: 'topics',
                  options: {
                    initialValue: topics
                  },
                  render: () => (
                    <Select
                      mode="tags"
                      tokenSeparators={[',']}
                      onChange={value => console.log(value)}
                    >
                      {topicList}
                    </Select>
                  )
                }
              ]
            }}
          </SharedForm>
        );
      }}
    </Query>
  );
}
