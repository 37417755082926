import { gql } from 'apollo-boost';

export const DELETE_COM = gql`
  mutation deleteComment($_id: ID!) {
    deleteComment(_id: $_id) {
      user {
        nickname
      }
      content
    }
  }
`;
