import React from 'react';
import dayjs from 'dayjs';
import './index.css';

export default function ApplicationCard({ application, onClick }) {
  if (!application) return null;

  const {
    status,
    imgUrl,
    profileUrl,
    title,
    school,
    desc,
    studentId,
    applicant,
    createdAt
  } = application;

  return (
    <div className="application-card-container" onClick={onClick}>
      <div className="application-card-aligner">
        <div className="application-card-upper">
          <div className="application-card-status">
            <div
              className="application-card-status-bubble"
              style={{
                background:
                  status === 'rejected'
                    ? '#D80F15'
                    : status === 'pending'
                    ? '#1C5D99'
                    : ''
              }}
            >
              {status}
            </div>
          </div>
          <img
            className="application-card-img"
            src={imgUrl || profileUrl}
            alt=""
          ></img>
        </div>
        <div className="application-card-details">
          <div className="application-card-title">
            {title || school || 'No title'}
          </div>
          <div className="application-card-desc">
            {desc || studentId || 'No desc'}
          </div>

          <div className="application-card-applicant">
            {(applicant || {}).nickname}
          </div>

          <div className="application-card-date">
            {dayjs(createdAt).format('MM-DD HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
}
