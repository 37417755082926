import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import MessageFeed from '../../containers/MessageFeed';
import UserFeed from '../../containers/UserFeed';
import BannedList from '../../containers/BannedList';
import ReportList from '../../containers/ReportList';
import Dir from '../../containers/Dir';
import NavMenu from '../../components/NavMenu';
import NavBar from '../../components/NavBar';
import BannerSetting from '../../containers/BannerSetting';
// import FeaturedList from '../../containers/FeaturedList';
import CartonList from '../../containers/CartonList';
import ApplicationList from '../../containers/ApplicationList';
import './index.css';

const { Content, Footer } = Layout;

export default function HomePage() {
  return (
    <Layout className="homePage">
      <Router>
        <NavMenu />
        <Layout className="right-panel">
          <NavBar />
          <Content className="workspace">
            <Dir></Dir>
            <Switch>
              <Route path="/message/:pageNum" component={MessageFeed} />
              <Route path="/applications" component={ApplicationList} />
              <Route path="/users/:pageNum" component={UserFeed} />
              <Route path="/banned" component={BannedList} />
              <Route path="/cartons" component={CartonList} />
              <Route path="/reports/:pageNum" component={ReportList} />
              <Route path="/banners" component={BannerSetting} />
              <Route path="/" exact component={MessageFeed} />
            </Switch>
          </Content>
          <Footer className="footer">
            纸条 ©2019 Created with Love, Sweat, and Tears
          </Footer>
        </Layout>
      </Router>
    </Layout>
  );
}
