import React from 'react';
import { Query } from 'react-apollo';
import { GET_USER_DETAIL } from '../../api/user';
import SharedForm from '../../components/SharedForm';
import Loading from '../../components/Loading';
import { Input, Select } from 'antd';
const { Option } = Select;

export default function UserForm({ onSubmit, onSetRef, _id }) {
  if (!_id) return null;

  return (
    <Query query={GET_USER_DETAIL} variables={{ _id }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error!</p>;
        const { nickname, major, grade, gender, school, celebrity } =
          data.user || {};

        return (
          <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
            {{
              formItems: [
                {
                  title: 'Nickname',
                  name: 'nickname',
                  options: {
                    initialValue: nickname,
                    rules: [
                      { required: true, message: 'Please input username' },
                    ],
                  },
                  render: () => <Input style={{ width: '100%' }} />,
                },
                {
                  title: 'Major',
                  name: 'major',
                  options: {
                    initialValue: major,
                    rules: [
                      { required: !!major, message: 'Please input major' },
                    ],
                  },
                  render: () => <Input style={{ width: '100%' }} />,
                },
                {
                  title: 'Grade',
                  name: 'grade',
                  options: {
                    initialValue: grade,
                    rules: [
                      { required: !!grade, message: 'Please input grade' },
                    ],
                  },
                  render: () => (
                    <Select style={{ width: '40%' }}>
                      <Option value="大一">大一</Option>
                      <Option value="大二">大二</Option>
                      <Option value="大三">大三</Option>
                      <Option value="大四">大四</Option>
                      <Option value="研士">研士</Option>
                      <Option value="博士">博士</Option>
                      <Option value="教职">教职</Option>
                      <Option value="校友">校友</Option>
                    </Select>
                  ),
                },
                {
                  title: 'Gender',
                  name: 'gender',
                  options: {
                    initialValue: gender,
                    rules: [
                      { required: !!gender, message: 'Please input gender' },
                    ],
                  },
                  render: () => (
                    <Select style={{ width: '40%' }}>
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                    </Select>
                  ),
                },
                {
                  title: 'School',
                  name: 'school',
                  options: {
                    initialValue: school,
                    rules: [
                      { required: !!school, message: 'Please input school' },
                    ],
                  },
                  render: () => <Input style={{ width: '100%' }} />,
                },
                {
                  title: 'Celebrity',
                  name: 'celebrity',
                  options: {
                    initialValue: celebrity,
                  },
                  render: () => <Input style={{ width: '100%' }} />,
                },
              ],
            }}
          </SharedForm>
        );
      }}
    </Query>
  );
}
