import React, { Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';
import { GET_USER_DETAIL } from '../../api/user';
import { GET_APPLICATION } from '../../api/application';
import { Button } from 'antd';
import Loading from '../Loading';
import 'react-day-picker/lib/style.css';
import './index.css';

function ApplicationDetails({ _id, _handleOperation }) {
  if (!_id) return null;

  return (
    <div>
      <Query query={GET_APPLICATION} variables={{ _id }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <p>Error!</p>;
          const { application = {} } = data;
          return (
            <div>
              <div className="application-detail-title">Applicant</div>
              <Query
                query={GET_USER_DETAIL}
                variables={{ _id: application.applicant._id }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <Loading />;
                  if (error) return <p>Error!</p>;
                  const { user = {} } = data;
                  return (
                    <div>
                      <div
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                      >
                        <div className="application-detail-user">
                          <img
                            className="application-detail-user-avatar"
                            src={user.avatarUrl}
                            alt=""
                          ></img>
                          <div className="application-detail-user-titles">
                            <div className="application-detail-user-details">
                              <div className="application-detail-user-field">
                                {user.nickname}
                              </div>
                              <div className="application-detail-user-field">
                                ({user.gender})
                              </div>
                            </div>
                            <div className="application-detail-user-id">
                              {user._id}
                            </div>
                          </div>
                        </div>
                        {user.identity ? (
                          <div className="application-detail-user-identity">
                            <div className="application-detail-user-school">
                              {user.identity.school}
                            </div>
                            <div className="application-detail-user-studentId">
                              {user.identity.studentId}
                            </div>
                            <div className="application-detail-user-studentId">
                              {user.phone}
                            </div>
                          </div>
                        ) : (
                          <div className="application-detail-user-noIdentity">
                            <div className="application-detail-user-message">
                              The user is not identified!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }}
              </Query>

              <div className="application-detail-title">Application</div>
              <div className="application-detail-application">
                <a
                  className="application-detail-img"
                  href={application.imgUrl || application.profileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={application.imgUrl || application.profileUrl}
                    alt=""
                  />
                </a>
                <div className="application-detail-details">
                  <div className="application-detail-field">
                    {application.title || application.school || 'No title'}
                  </div>
                  <div className="application-detail-field">
                    {application.desc || application.studentId || 'No desc'}
                  </div>
                  <div className="application-detail-field">
                    status: {application.status}
                  </div>
                </div>
              </div>

              {application.status === 'pending' &&
                <Fragment>
                  <div className="application-detail-title">Actions</div>
                  <div className="application-detail-actions-container">
                    <Button
                      icon="check"
                      type="primary"
                      // disabled={application.status === 'authorized'}
                      onClick={() => _handleOperation('Authorize')}
                    >
                      Authorize
                    </Button>
                    <Button
                      icon="delete"
                      type="danger"
                      className="carton-list-modal-button"
                      style={{ marginLeft: '10px' }}
                      // disabled={application.status === 'rejected'}
                      onClick={() => _handleOperation('Reject')}
                    >
                      Reject
                    </Button>
                  </div>
                </Fragment>
              }
            </div>
          );
        }}
      </Query>
    </div>
  );
}

export default withApollo(ApplicationDetails);
