import React from 'react';
import { Icon, Input, Button, Checkbox } from 'antd';
import SharedForm from '../../components/SharedForm';

export default function AuthForm({ onSubmit }) {
  return (
    <SharedForm onSubmit={onSubmit} style={formStyle}>
      {{
        formItems: [
          {
            name: 'username',
            layout: {},
            options: {
              rules: [
                { required: true, message: 'Please input your username!' }
              ]
            },
            render: () => (
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Username"
              />
            )
          },
          {
            name: 'password',
            layout: {},
            options: {
              rules: [
                { required: true, message: 'Please input your Password!' }
              ]
            },
            render: () => (
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Password"
              />
            )
          },
          {
            name: 'remember',
            options: {
              valuePropName: 'checked',
              initialValue: true
            },
            render: () => <Checkbox>Remember me</Checkbox>
          }
        ],
        submitButton: {
          render: () => (
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          )
        }
      }}
    </SharedForm>
  );
}

const formStyle = {
  margin: '0px 15px'
};
