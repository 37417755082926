import { gql } from 'apollo-boost';

export const GET_BANNERS = gql`
  query getBanners {
    banners {
      _id
      imgUrl
      url
      content
      desc
      carton {
        title
        _id
      }
      topic
      type
      school
      priority
    }
  }
`;

export const SET_BANNER = gql`
  mutation updateBanner($_id: ID!, $bannerData: BannerData!) {
    updateBanner(_id: $_id, bannerData: $bannerData) {
      _id
    }
  }
`;

export const CREATE_BANNER = gql`
  mutation createBanner($bannerData: BannerData!) {
    createBanner(bannerData: $bannerData) {
      _id
    }
  }
`;

export const DELETE_BANNER = gql`
  mutation deleteBanner($_id: ID!) {
    deleteBanner(_id: $_id) {
      _id
    }
  }
`;
