import React, { Component, Fragment } from 'react';
import { withApollo, Query } from 'react-apollo';
import MessageCard from '../../components/MessageCard';
import {
  GET_MESSAGES,
  CREATE_MESSAGE,
  DELETE_MSG,
  UPDATE_MESSAGE_DETAIL,
} from '../../api/message';
import { GET_CURRENT_USER } from '../../api/user';
import './index.css';
import MessageDetail from '../../components/MessageDetail';
import MessageForm from './MessageForm';
import MessagePost from './MessagePost';
import ModalBox from '../../components/ModalBox';
import Loading from '../../components/Loading';
import { Pagination, Select, Button, message, Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const { Option } = Select;

class MessageFeed extends Component {
  state = {
    operationType: '',
    currentMsgId: '',
    showMessagePost: false,
    searchType: 'content',
    showSelector: false,
    showMessageDetail: false,
    selectedMessages: [],
    keyword: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.keyword !== prevState.keyword ||
      (!this.state.showSelector && prevState.showSelector)
    ) {
      this._clearSelection();
    }
  }

  _showMessagePost = () => 
    this.setState({ showMessagePost: true });

  _closeMessagePost = () =>
    this.setState({ showMessagePost: false });

  _clearSelection = () =>
    this.setState({ selectedMessages: [], showSelector: false });

  _navigationOnchange = pageNum => {
    this.props.history.push('/message/' + pageNum);
  };

  _toggleSelector = () => {
    const current = this.state.showSelector;
    this.setState({ showSelector: !current });
  };

  _handleSelect = _id => {
    this.setState(({ selectedMessages }) => ({
      selectedMessages: selectedMessages.includes(_id)
        ? selectedMessages.filter(selectedId => selectedId !== _id)
        : selectedMessages.concat(_id),
    }));
  };

  _handleUpdate = (_id, data) => {
    this.props.client
      .mutate({
        mutation: UPDATE_MESSAGE_DETAIL,
        variables: { _id, data },
        refetchQueries: () => [
          {
            query: GET_MESSAGES,
            variables: this._getSearchTerm(),
          },
        ],
      })
      .then(() => message.success('Message updated!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
    this._clearSelection();
    this._onCloseModal();
  };

  _handleDelete = _id => {
    this.props.client
      .mutate({
        mutation: DELETE_MSG,
        variables: { _id },
        refetchQueries: () => [
          {
            query: GET_MESSAGES,
            variables: this._getSearchTerm(),
          },
        ],
      })
      .then(() => message.success('Message deleted!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
    this._clearSelection();
    this._onCloseModal();
  };

  _handleDeleteAll = () => {
    this.state.selectedMessages.map(msgId => this._handleDelete(msgId));
  };

  _getSearchTerm = () => {
    const { searchType, keyword } = this.state;
    const { pageNum = 1 } = this.props.match.params;
    return {
      pageSize: keyword ? 100 : 15,
      currentPage: keyword ? 1 : pageNum * 1,
      ...(keyword && {
        searchTerm: { [searchType]: keyword },
      }),
    };
  };

  _cancelSearch = () => this.setState({ keyword: '' });

  _onCloseModal = () => {
    this.setState({
      currentMsgId: '',
      operationType: '',
      showMessageDetail: false,
    });
  };

  _handleButtonClick = (_id, type) => {
    this.setState({
      currentMsgId: _id,
      operationType: type,
    });
  };

  _createMessage = () => {
    const { form } = this.msgRef.props;
    form.validateFields((err, values) => {
      let userId = '5fd63bfeac72383eedf9a8bd';
      let display = {
        gender: false,
        grade: false,
        major: false
      }
      if (values.user === '某男生') {
        userId = '5fd63c16ac72383eedf9a8be';
        display.gender = true;
      }
      if (values.user === '某女生') {
        userId = '5fd63c35ac72383eedf9a8bf';
        display.gender = true;
      }
      const msg = {
        user: userId,
        content: values.content,
        isAnom: true,
        school: '广西大学',
        display
      };
      this.props.client
        .mutate({
          mutation: CREATE_MESSAGE,
          variables: {
            data: msg
          },
          refetchQueries: () => [
            {
              query: GET_MESSAGES,
              variables: this._getSearchTerm(),
            },
          ]
        })
        .then(() => {
          message.success('发布成功!');
          this._closeMessagePost();
        })
        .catch(err => message.error(err.message || '未知错误!'));
    });
  };

  _handleSubmit = () => {
    const { selectedMessages, currentMsgId, operationType } = this.state;
    if (selectedMessages.length && operationType === 'Delete')
      return this._handleDeleteAll();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) return;
      if (operationType === 'Delete') {
        //delete message
        this._handleDelete(currentMsgId); // delete signle message with reason
      } else {
        //edit message
        this._handleUpdate(currentMsgId, values);
      }
    });
  };

  setRef = ref => {
    this.formRef = ref;
  };

  _toggleDetails = _id => {
    this.setState(({ showMessageDetail }) => ({
      showMessageDetail: !showMessageDetail,
      currentMsgId: _id,
    }));
  };

  render() {
    const { pageNum = 1 } = this.props.match.params;
    const {
      keyword,
      searchType,
      showSelector,
      selectedMessages,
      showMessageDetail,
      currentMsgId,
      operationType,
      showMessagePost
    } = this.state;

    return (
      <Query query={GET_CURRENT_USER}>
        {({ data: { currentUser } }) => {
          const isAdmin = currentUser.role === 'admin';
          return (
            <div className="list-area">
              <div className="message-top-buttons">
                <div className="aligner">
                  <input
                    placeholder="Search for a message"
                    className="search-input"
                    onKeyDown={e => {
                      if (e.key === 'Enter')
                        this.setState({ keyword: e.target.value });
                    }}
                  />

                  {isAdmin &&
                    <Select
                      style={{ marginRight: '8px' }}
                      onChange={type => this.setState({ searchType: type })}
                      defaultValue={searchType}
                    >
                      <Option value="_id">_id</Option>
                      <Option value="content">content</Option>
                      <Option value="user">user</Option>
                    </Select>
                  }

                  {keyword && (
                    <Button
                      className="message-top-button"
                      icon="close"
                      type="danger"
                      shape="circle"
                      onClick={this._cancelSearch}
                    />
                  )}
                </div>

                <div className="aligner">
                  <Button
                    className="message-top-button"
                    onClick={this._toggleSelector}
                  >
                    {showSelector ? 'Hide' : 'Select'}
                  </Button>

                  {selectedMessages.length > 1 && (
                    <Button
                      className="message-top-button"
                      icon="delete"
                      type="danger"
                      onClick={() => this.setState({ operationType: 'Delete' })}
                    >
                      Delete all
                    </Button>
                  )}
                </div>
              </div>
              <Query query={GET_MESSAGES} variables={this._getSearchTerm()}>
                {({ loading, error, data }) => {
                  if (loading) return <Loading />;
                  if (error) return <p>Error!</p>;
                  const { messages = [] } = data;
                  return messages.length ? (
                    <Fragment>
                      <p>Found {messages.length} matches</p>
                      <div className="message-feed">
                        {messages.map(msg => (
                          <MessageCard
                            showEdit={isAdmin}
                            key={msg._id}
                            message={msg}
                            onPress={this._handleButtonClick}
                            onSelect={this._handleSelect}
                            showDetails={this._toggleDetails}
                            showSelector={showSelector}
                            checked={selectedMessages.includes(msg._id)}
                          />
                        ))}
                      </div>
                      <Pagination
                        showQuickJumper
                        defaultCurrent={pageNum * 1}
                        total={500}
                        onChange={this._navigationOnchange}
                      />
                    </Fragment>
                  ) : (
                    <Empty className="empty-hint" />
                  );
                }}
              </Query>

              <ModalBox
                visible={showMessageDetail}
                title={`Message details (id: ${currentMsgId})`}
                onConfirm={this._onCloseModal}
                onCancel={this._onCloseModal}
              >
                <MessageDetail _id={currentMsgId} onDelete={this._onCloseModal} />
              </ModalBox>

              <ModalBox
                visible={!!operationType}
                title={`${operationType} message (id: ${currentMsgId})`}
                onConfirm={this._handleSubmit}
                onCancel={this._onCloseModal}
              >
                <MessageForm
                  operationType={operationType}
                  onSubmit={this._handleSubmit}
                  onSetRef={this.setRef}
                  _id={currentMsgId}
                />

                <p style={{ fontWeight: '500', color: '#D80F15' }}>
                  Are you sure you want to proceed?
                </p>
              </ModalBox>
            </div>
          )
        }}
      </Query>
    );
  }
}

export default withApollo(withRouter(MessageFeed));
