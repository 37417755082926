import React, { Component } from 'react';
import { Query, withApollo } from 'react-apollo';
import { Button, message, Popconfirm } from 'antd';
import ModalBox from '../../components/ModalBox';
import BanUserForm from './BanUserForm';
import Loading from '../../components/Loading';
import {
  GET_BANNED_USERS,
  GET_BANNED_ANOM_USERS,
  BAN_USER,
  UN_BAN_USER,
} from '../../api/banned';
import './index.css';

const UsersList = ({ query, name, onEdit, onRemove }) => (
  <div>
    <h2>{name}</h2>
    <Query query={query} policy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error!</p>;
        return data[name].map(user => (
          <div className="banned-item" key={`${name}-${user._id}`}>
            <div className="banned-user">
              <img
                className="message-detail-user-avatar"
                src={user.avatarUrl}
                alt=""
              />
              <div>{user.nickname}</div>
            </div>
            <div>{user.endDate}</div>
            <div className="banned-btn-group">
              <Button
                shape="circle"
                icon="tool"
                type="primary"
                ghost
                onClick={() => onEdit(user)}
              />
              <Popconfirm
                title="Are you sure to free this monster?"
                onConfirm={() => onRemove(user)}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" icon="smile" type="primary" />
              </Popconfirm>
            </div>
          </div>
        ));
      }}
    </Query>
  </div>
);

const BannedUsersList = ({ ...args }) => (
  <UsersList query={GET_BANNED_USERS} name="bannedUsers" {...args} />
);
const BannedAnomUsersList = ({ ...args }) => (
  <UsersList query={GET_BANNED_ANOM_USERS} name="bannedAnomUsers" {...args} />
);

class BannedList extends Component {
  state = {
    modalVisible: false,
    currentBannedUser: null,
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      currentBannedUser: null,
    });
  };

  addBannedUser = () => {
    this.setState({
      modalVisible: true,
      currentBannedUser: null,
    });
  };

  handleRemoveBan = user => {
    this.props.client
      .mutate({
        mutation: UN_BAN_USER,
        variables: user,
        refetchQueries: () => [
          { query: GET_BANNED_USERS },
          { query: GET_BANNED_ANOM_USERS },
        ],
      })
      .then(() => message.success('User unbanned!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
  };

  handleEditBan = user => {
    this.setState({
      modalVisible: true,
      currentBannedUser: user,
    });
  };

  setRef = ref => {
    this.formRef = ref;
  };

  onConfirmBan = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) return;
      this.closeModal();
      form.resetFields();
      this.props.client
        .mutate({
          mutation: BAN_USER,
          variables: values,
          refetchQueries: () => [
            { query: GET_BANNED_USERS },
            { query: GET_BANNED_ANOM_USERS },
          ],
        })
        .then(() => message.success('User Banned!'))
        .catch(err => message.error(err.message || 'Error occurred!'));
    });
  };

  renderBanModal = () => {
    const { currentBannedUser, modalVisible } = this.state;
    return (
      <ModalBox
        visible={modalVisible}
        title="Ban New User"
        onCancel={this.closeModal}
        onConfirm={this.onConfirmBan}
      >
        <BanUserForm
          onSubmit={this.onConfirmBan}
          bannedUser={currentBannedUser}
          onSetRef={this.setRef}
        />
      </ModalBox>
    );
  };

  render() {
    return (
      <div>
        <div className="banned-list">
          <BannedUsersList
            onEdit={this.handleEditBan}
            onRemove={this.handleRemoveBan}
          />
          <BannedAnomUsersList
            onEdit={this.handleEditBan}
            onRemove={this.handleRemoveBan}
          />
        </div>
        {this.renderBanModal()}
        <Button
          icon="plus"
          shape="circle"
          type="primary"
          size="large"
          className="topic-add-btn"
          onClick={this.addBannedUser}
        />
      </div>
    );
  }
}

export default withApollo(BannedList);
