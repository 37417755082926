import { gql } from 'apollo-boost';

export const GET_BANNED_USERS = gql`
  query getBannedUsers {
    bannedUsers {
      _id
      nickname
      avatarUrl
      endDate
      isAnom
    }
  }
`;

export const GET_BANNED_ANOM_USERS = gql`
  query getBannedAnomUsers {
    bannedAnomUsers {
      _id
      nickname
      avatarUrl
      endDate
      isAnom
    }
  }
`;

export const BAN_USER = gql`
  mutation banUser($_id: ID!, $endDate: String!, $isAnom: Boolean) {
    banUser(_id: $_id, endDate: $endDate, isAnom: $isAnom) {
      _id
      nickname
      avatarUrl
      endDate
      isAnom
    }
  }
`;

export const UN_BAN_USER = gql`
  mutation unBanUser($_id: ID!, $isAnom: Boolean) {
    unBanUser(_id: $_id, isAnom: $isAnom) {
      _id
    }
  }
`;
