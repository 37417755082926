export default {
  Mutation: {
    setUser: (_, { user, token }, { cache }) => {
      const data = {
        currentUser: {
          ...user,
          __typename: 'CurrentUser'
        },
        token
      };
      cache.writeData({ data });
    },
    logOut: (_, args, { cache }) => {
      cache.writeData({
        data: {
          currentUser: null,
          token: null
        }
      });
    }
  }
};
