import { gql } from 'apollo-boost';

export const GET_APPLICATIONS = gql`
  query applications(
    $pageSize: Int
    $currentPage: Int
    $searchTerm: SearchApplicationFilter!
  ) {
    applications(
      pageSize: $pageSize
      currentPage: $currentPage
      searchTerm: $searchTerm
    ) {
      _id
      applicant {
        _id
        nickname
      }
      type
      status
      createdAt
      title
      desc
      imgUrl
      studentId
      school
      profileUrl
    }
  }
`;

export const GET_APPLICATION = gql`
  query application($_id: ID!) {
    application(_id: $_id) {
      _id
      applicant {
        _id
      }
      type
      status
      createdAt
      updatedAt
      rejectReason
      title
      desc
      imgUrl
      reason
      studentId
      school
      profileUrl
    }
  }
`;

export const AUTHORIZE_APPLICATION = gql`
  mutation authorizeApplication($_id: ID!) {
    authorizeApplication(_id: $_id) {
      status
    }
  }
`;

export const REJECT_APPLICATION = gql`
  mutation rejectApplication($_id: ID!, $rejectReason: String!) {
    rejectApplication(_id: $_id, rejectReason: $rejectReason) {
      status
      rejectReason
    }
  }
`;
