import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './index.css';

function Banner({ banner, onDelete, onEdit }) {
  const {
    _id,
    imgUrl,
    type,
    url,
    content,
    desc,
    carton,
    topic,
    school,
    priority,
  } = banner;
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(_id);
  };
  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(banner);
  };

  return (
    <div className={`banner-container ${priority < 0 ? 'offline' : ''}`}>
      <div className={`banner-container-upper ${type}`}>
        <div className="banner-container-type">{type}</div>
        <img className="banner-container-img" src={imgUrl} alt="" />
        <div className="banner-container-priority">{priority}</div>
      </div>
      <div className="banner-container-lower">
        {content && <div className="banner-container-desc">{content}</div>}
        {desc && <div className="banner-container-desc">{desc}</div>}
        {carton && <div className="banner-container-desc">{carton.title}</div>}
        {topic && <div className="banner-container-desc">{topic}</div>}
        {url && <div className="banner-container-url">{url}</div>}
        {school && <div className="banner-container-school">{school}</div>}
      </div>

      <div className="banner-container-buttons">
        <Button
          className="banner-container-button"
          type="primary"
          icon="delete"
          onClick={handleEdit}
        >
          Edit
        </Button>

        <Button
          className="banner-container-button"
          type="danger"
          icon="delete"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}

Banner.propTypes = {
  banner: PropTypes.shape({
    _id: PropTypes.string,
    imgUrl: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default Banner;
