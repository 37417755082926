import { gql } from 'apollo-boost';

// Get current user (local global state query)
export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser @client {
      username
      nickname
      role
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        role
        nickname
        username
      }
      token
    }
  }
`;

export const SET_USER = gql`
  mutation SetUser($user: UserInput!, $token: String!) {
    setUser(user: $user, token: $token) @client {
      username
      nickname
      role
      token
    }
  }
`;

export const GET_TOKEN = gql`
  query getToken {
    token @client
  }
`;

export const LOG_OUT = gql`
  mutation LogOut {
    logOut @client
  }
`;

export const UPDATE_USER_DETAIL = gql`
  mutation UpdateUser($_id: ID!, $data: CreateUserInput!) {
    updateUser(_id: $_id, data: $data) {
      nickname
    }
  }
`;

export const BAN_USER = gql`
  mutation BanUser($_id: ID!, $date: String!) {
    banUser(_id: $_id, date: $date) {
      nickname
    }
  }
`;

export const UN_BAN_USER = gql`
  mutation UnBanUser($_id: ID!) {
    unBanUser(_id: $_id) {
      nickname
    }
  }
`;

export const GET_USERS = gql`
  query users(
    $pageSize: Int!
    $currentPage: Int!
    $searchTerm: SearchUserFilter
  ) {
    users(
      pageSize: $pageSize
      currentPage: $currentPage
      searchTerm: $searchTerm
    ) {
      _id
      avatarUrl
      nickname
      major
      grade
      phone
      gender
      school
      celebrity
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query user($_id: ID!) {
    user(_id: $_id) {
      _id
      nickname
      gender
      celebrity
      avatarUrl
      grade
      phone
      major
      school
      province
      updatedAt
      createdAt
      agreeTerms
      identity {
        school
        studentId
        name
      }
      followers {
        nickname
        avatarUrl
      }
      following {
        nickname
        avatarUrl
      }
      cartons {
        title
        imgUrl
      }
    }
  }
`;
