import { gql } from 'apollo-boost';

export const GET_CARTONS = gql`
  query cartons($searchTerm: SearchCartonFilter!) {
    cartons(searchTerm: $searchTerm) {
      _id
      title
      desc
      imgUrl
      manager {
        _id
        nickname
      }
      topics
      category
      school
    }
  }
`;

export const GET_CARTON_DETAILS = gql`
  query carton($_id: ID!) {
    carton(_id: $_id) {
      _id
      title
      desc
      imgUrl
      manager {
        _id
        nickname
      }
      topics
      category
      school
      hotFactor
    }
  }
`;

export const CREATE_CARTON = gql`
  mutation CreateCarton($data: CreateCartonInput!) {
    createCarton(data: $data) {
      _id
    }
  }
`;

export const DELETE_CARTON = gql`
  mutation DeleteCarton($_id: ID!) {
    deleteCarton(_id: $_id) {
      _id
    }
  }
`;

export const UPDATE_CARTON = gql`
  mutation UpdateCarton($_id: ID!, $data: CreateCartonInput!) {
    updateCarton(_id: $_id, data: $data) {
      _id
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    cartons {
      category
    }
  }
`;
