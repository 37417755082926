import React, { Component } from 'react';
import { Menu, Icon, Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { GET_CURRENT_USER } from '../../api/user';
import './index.css';

// const { SubMenu } = Menu;
const { Sider } = Layout;

class NavMenu extends Component {
  state = {
    collapsed: false,
    current: '',
    width: 200,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onBreakpoint = (broken) => {
    this.setState({
      width: broken ? 0 : 200,
    });
  };

  handleClick = ({ key }) => {
    this.setState({ current: key });
    let route;
    if (key === 'message') route = '/message/1';
    else if (key === 'users') route = '/users/1';
    else if (key === 'reports') route = '/reports/1';
    else route = `/${key}`;
    this.props.history.push(route);
  };

  render() {
    let selectedKey = this.props.location.pathname;
    selectedKey =
      selectedKey !== '/'
        ? selectedKey.substring(1, selectedKey.length).split('/')[0]
        : 'message';

    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        breakpoint="sm"
        // trigger={null}
        collapsedWidth={0}
        onBreakpoint={this.onBreakpoint}
      >
        <div className="logo">纸条</div>
        
        <Query query={GET_CURRENT_USER}>
          {({ data: { currentUser } }) => {
            if (currentUser.role === 'admin') return (
              <Menu
                onClick={this.handleClick}
                theme="dark"
                defaultSelectedKeys={[selectedKey]}
                mode="inline"
              >
                <Menu.Item key="message">
                  <Icon type="pie-chart" />
                  <span>Timeline</span>
                </Menu.Item>

                <Menu.Item key="reports">
                  <Icon type="desktop" />
                  <span>Reports</span>
                </Menu.Item>

                <Menu.Item key="users">
                  <Icon type="user" />
                  <span>Users</span>
                </Menu.Item>

                <Menu.Item key="banned">
                  <Icon type="disconnect" />
                  <span>Banned</span>
                </Menu.Item>

                <Menu.Item key="cartons">
                  <Icon type="code-sandbox" />
                  <span>Cartons</span>
                </Menu.Item>

                <Menu.Item key="applications">
                  <Icon type="copy" />
                  <span>Applications</span>
                </Menu.Item>

                <Menu.Item key="banners">
                  <Icon type="table" />
                  <span>Banners</span>
                </Menu.Item>
              </Menu>
            );
            return (
              <Menu
                onClick={this.handleClick}
                theme="dark"
                defaultSelectedKeys={[selectedKey]}
                mode="inline"
              >
                <Menu.Item key="message">
                  <Icon type="pie-chart" />
                  <span>Timeline</span>
                </Menu.Item>

                <Menu.Item key="reports">
                  <Icon type="desktop" />
                  <span>Reports</span>
                </Menu.Item>

                <Menu.Item key="applications">
                  <Icon type="copy" />
                  <span>Applications</span>
                </Menu.Item>

                <Menu.Item key="cartons">
                  <Icon type="code-sandbox" />
                  <span>Cartons</span>
                </Menu.Item>

              </Menu>
            );
          }}
        </Query>
      </Sider>
    );
  }
}

export default withRouter(NavMenu);
