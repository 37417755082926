import React from 'react';
import SharedForm from '../../components/SharedForm';
import { Input } from 'antd';

export default function ApplicationForm({ onSubmit, onSetRef, operation, application }) {
  if (!operation) return null;
  if (operation === 'Authorize') {
    return (
      <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
        {{
          formItems: [
            {
              title: 'Application',
              name: '_id',
              options: {
                initialValue: application,
                rules: [
                  {
                    required: true,
                    message: 'Please enter _id for authorization'
                  }
                ]
              },
              render: () => <Input style={{ width: '100%' }} />
            }
          ]
        }}
      </SharedForm>
    );
  } else {
    return (
      <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
        {{
          formItems: [
            {
              title: 'Reason',
              name: 'reason',
              options: {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Please enter reason for rejection'
                  }
                ]
              },
              render: () => <Input style={{ width: '100%' }} />
            }
          ]
        }}
      </SharedForm>
    );
  }
}
