import React, { Component } from 'react';
import axios from 'axios';
import { ZHITIAO_HOST } from '../../config';
import { Query, withApollo } from 'react-apollo';
import { Pagination, Select, Button, message, Empty } from 'antd';
import Report from '../../components/Report';
import ModalBox from '../../components/ModalBox';
import Loading from '../../components/Loading';
import BanUserForm from '../BannedList/BanUserForm';
import {
  GET_BANNED_USERS,
  GET_BANNED_ANOM_USERS,
  BAN_USER,
} from '../../api/banned';
import { GET_REPORTS, UPDATE_REPORT } from '../../api/report';
import './index.css';

const { Option } = Select;

class ReportList extends Component {
  state = {
    showBan: false,
    reportType: 'all',
    handledType: 'notHandled',
    filterType: 'all',
    currentReportId: '',
    currentReason: '',
    currentReport: {}
  };

  _navigationOnchange = pageNum => {
    this.props.history.push('/reports/' + pageNum);
  };

  _changeReportType = type => {
    this.setState({
      reportType: type
    });
    this._resetPages();
  };

  _changeHandledType = type => {
    this.setState({
      handledType: type
    });
    this._resetPages();
  };

  _changeFilterType = filter => {
    this.setState({
      filterType: filter
    });
    this._resetPages();
  };

  _changeCurrentReason = reason => {
    this.setState({
      currentReason: reason
    });
  };

  _resetPages = () => {
    this._navigationOnchange(1);
  };

  _closeModal = () => {
    this.setState({
      currentReportId: '',
      currentReason: '',
      currentReport: {}
    });
  };

  _handleDelete = () => {
    const { currentReport, currentReason } = this.state;
    const { _id } = currentReport;
    const report = currentReason === currentReport.reason ? '' : `?reason=${encodeURI(currentReason)}`;
    if (_id) {
      axios.get(`${ZHITIAO_HOST}/report/${_id}/delete${report}`, {
        headers: {
          time: new Date().toUTCString()
        }
      })
        .then(() => this._handleReport());
    }
  };

  _handleReport = () => {
    const { currentReportId: id } = this.state;
    const { pageNum = 1 } = this.props.match.params;
    this.props.client
      .mutate({
        mutation: UPDATE_REPORT,
        variables: { id, handled: true },
        refetchQueries: () => [
          {
            query: GET_REPORTS,
            variables: {
              pageSize: 15,
              currentPage: pageNum * 1
            }
          }
        ]
      })
      .then(() => message.success('Reported Entity handled!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
    this._closeModal();
  };

  setRef = ref => {
    this.formRef = ref;
  };

  _handleBan = () =>
    this.setState({ showBan: true });

  closeBan = () =>
    this.setState({ showBan: false });
  
  onConfirmBan = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) return;
      this.props.client
        .mutate({
          mutation: BAN_USER,
          variables: values,
          refetchQueries: () => [
            { query: GET_BANNED_USERS },
            { query: GET_BANNED_ANOM_USERS },
          ],
        })
        .then(() => message.success('User Banned!'))
        .catch(err => message.error(err.message || 'Error occurred!'));
      this.closeBan();
    });
  };

  render() {
    const {
      showBan,
      filterType,
      reportType,
      currentReason,
      handledType,
      currentReport,
      currentReportId
    } = this.state;

    const { pageNum = 1 } = this.props.match.params;

    const { targetUser } = currentReport;

    const { content, imgUrls } = currentReport.relatedMsg || currentReport.relatedCom || {};

    const reportedItem = (
      <div>
        <div className="content">{content}</div>
        <div>
          {
            imgUrls ? imgUrls.map((url) =>
              <img
                key={url}
                style={{marginRight: '10px', maxWidth: '150px', maxHeight: '180px'}}
                alt="消息图片"
                src={`https://cdn.zhitiaox.com/${url}`}
              />
            ) : ''
          }
        </div>
      </div>
    );

    return (
      <div className="list-area">
        <div className="report-top-buttons">
          <Select
            className="filterSelector"
            onChange={type => this._changeReportType(type)}
            defaultValue={reportType}
          >
            <Option value="all">All reports</Option>
            <Option value="message">Message</Option>
            <Option value="comment">Comment</Option>
          </Select>

          <Select
            className="filterSelector"
            onChange={type => this._changeHandledType(type)}
            defaultValue={handledType}
          >
            <Option value="all">All status</Option>
            <Option value="notHandled">Pending</Option>
            <Option value="handled">Handled</Option>
          </Select>

          <Select
            className="filterSelector"
            onChange={filter => this._changeFilterType(filter)}
            defaultValue={filterType}
          >
            <Option value="all">所有举报</Option>
            <Option value="键盘政治">键盘政治</Option>
            <Option value="垃圾广告">垃圾广告</Option>
            <Option value="违法信息">违法信息</Option>
            <Option value="色情低俗">色情低俗</Option>
            <Option value="人身攻击">人身攻击</Option>
            <Option value="不实信息">不实信息</Option>
            <Option value="学术不端">学术不端</Option>
            <Option value="带节奏/引战">带节奏/引战</Option>
          </Select>
        </div>
        <Query
          query={GET_REPORTS}
          variables={{
            pageSize: 15,
            currentPage: pageNum * 1,
            searchTerm: {
              ...(filterType !== 'all' && { reason: filterType }),
              ...(reportType !== 'all' && { type: reportType }),
              ...(handledType !== 'all' && {
                handled: handledType === 'handled'
              })
            }
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <p>Error!</p>;
            const { reports = [] } = data;

            return reports.length ? (
              <div className="report-list">
                {reports.map(report => (
                  <div
                    key={report._id}
                    onClick={() =>
                      this.setState({
                        currentReportId: report._id,
                        currentReport: report,
                        currentReason: report.reason
                      })
                    }
                  >
                    {
                      (
                        handledType === 'all' ||
                        (handledType === 'notHandled' && !report.handled) ||
                        (handledType === 'handled' && report.handled)
                      ) &&
                      <Report report={report} handleReport={this.handleReport} />
                    }
                  </div>
                ))}
              </div>
            ) : (
              <Empty className="empty-hint" />
            );
          }}
        </Query>

        <ModalBox
          visible={!!currentReportId}
          title={`被举报消息，来自用户：${targetUser && targetUser._id}`}
          onCancel={this._closeModal}
          hideFooter
        >
          <div className="report-table-form">
            {reportedItem}
            <div className="report-table-form-column">
              {
                currentReason &&
                <Select
                  style={{width: '150px', margin: '0 15px'}}
                  onChange={reason => this._changeCurrentReason(reason)}
                  defaultValue={currentReason}
                >
                  <Option value="键盘政治">键盘政治 -12分</Option>
                  <Option value="不宜讨论">不宜讨论 -3分</Option>
                  <Option value="垃圾广告">垃圾广告 -3分</Option>
                  <Option value="违法信息">违法信息 -3分</Option>
                  <Option value="色情低俗">色情低俗 -3分</Option>
                  <Option value="人身攻击">人身攻击 -3分</Option>
                  <Option value="不实信息">不实信息 -3分</Option>
                  <Option value="学术不端">学术不端 -3分</Option>
                  <Option value="带节奏/引战">带节奏/引战 -3分</Option>
                </Select>
              }
              <Button
                className="report-table-form-button"
                type="danger"
                icon="delete"
                onClick={this._handleDelete}
              >
                删除
              </Button>
            </div>
            <div className="report-table-form-column">
              <Button
                className="report-table-form-button"
                type="danger"
                icon="stop"
                onClick={this._handleBan}
              >
                Ban User
              </Button>
              <Button
                className="report-table-form-button"
                type="primary"
                icon="check"
                onClick={this._handleReport}
              >
                忽略
              </Button>
            </div>
          </div>
        </ModalBox>
        
        <ModalBox
          visible={showBan}
          title="Ban New User"
          onCancel={this.closeBan}
          onConfirm={this.onConfirmBan}
        >
          <BanUserForm
            onSubmit={this.onConfirmBan}
            bannedUser={targetUser}
            onSetRef={this.setRef}
          />
        </ModalBox>

        <Pagination
          showQuickJumper
          defaultCurrent={pageNum * 1}
          total={500}
          onChange={this._navigationOnchange}
        />
      </div>
    );
  }
}

export default withApollo(ReportList);
