import React from 'react';
import { Avatar, Button } from 'antd';
import './index.css';

export default function UserCard({ _handleClick, user }) {
  if (!user) return null;
  const { _id, avatarUrl, nickname, celebrity } = user;
  return (
    <div
      className="user-card-container"
      onClick={() => _handleClick(_id, 'View')}
    >
      <div className="user-card-container-top">
        <Avatar color="blue" className="user-card-avatar" src={avatarUrl} />
        <div className="user-card-details">
          <div className="user-card-details-column">{nickname}</div>
          <div
            className="user-card-details-column"
            style={{ color: '#44AF69', fontWeight: '500' }}
          >
            {celebrity ? `*${celebrity}*` : '普通用户'}
          </div>
        </div>
      </div>
      <div className="user-card-details-buttons">
        <Button
          className="user-card-button"
          icon="edit"
          type="primary"
          onClick={e => {
            _handleClick(_id, 'Edit');
            e.stopPropagation();
          }}
        >
          <span>Edit</span>
        </Button>
      </div>
    </div>
  );
}
