import React, { Component } from 'react';
import SharedForm from '../../components/SharedForm';
import { Input, Select, Icon, Checkbox } from 'antd';
const { Option } = Select;

export default class TopicForm extends Component {
  setInputValue = (val) => {
    const formatDate = date => {
      const formatZero = num =>
        num < 10 ? `0${num}` : num;
      return `${date.getFullYear()}-${formatZero(date.getMonth() + 1)}-${formatZero(date.getDate())}`
    };
    let inputVal = '';
    if (val === '3days')
      inputVal = formatDate(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000));
    if (val === '7days')
      inputVal = formatDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));
    if (val === '14days')
      inputVal = formatDate(new Date(Date.now() + 14 * 24 * 60 * 60 * 1000));
    else if (val === '30days')
      inputVal = formatDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000));
    else if (val === 'forever')
      inputVal = '2099-12-31';
    this.inputRef.setState({ value: inputVal });
    this.formRef.props.form.setFieldsValue({ endDate: inputVal });
  };

  _onSetRef = (ref) => {
    this.formRef = ref;
    this.props.onSetRef(ref);
  }

  render() {
    const { onSubmit, bannedUser } = this.props;
    const { _id, endDate, isAnom } = bannedUser || {};

    return (
      <SharedForm wrappedComponentRef={this._onSetRef} onSubmit={onSubmit}>
        {{
          formItems: [
            {
              title: 'User ID',
              name: '_id',
              options: {
                initialValue: _id,
                rules: [{ required: true, message: 'Please input user ID!' }],
              },
              render: () => (
                <Input
                  prefix={
                    <Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="User ID"
                />
              ),
            },
            {
              title: 'EndDate',
              name: 'endDate',
              options: {
                initialValue: endDate,
                rules: [{ required: true, message: 'Please choose endDate!' }],
              },
              render: () => (
                <div style={{ width: '100%', display: 'flex'}}>
                  <Select
                    style={{width: '30%'}}
                    defaultValue="manual"
                    onChange={this.setInputValue}
                  >
                    <Option value="manual">手动</Option>
                    <Option value="3days">3天</Option>
                    <Option value="7days">7天</Option>
                    <Option value="14days">14天</Option>
                    <Option value="30days">30天</Option>
                    <Option value="forever">永久</Option>
                  </Select>
                  <Input
                    ref={(el) => this.inputRef = el}
                    style={{width: '70%'}}
                    prefix={
                      <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="例：2020-12-01"
                  />
                </div>
              ),
            },
            {
              title: '只禁匿名',
              name: 'isAnom',
              options: {
                initialValue: typeof isAnom === 'boolean' ? isAnom : false,
                valuePropName: 'checked',
                rules: [{ required: true, message: 'Please confirm isAnom!' }],
              },
              render: () => <Checkbox />,
            },
          ],
        }}
      </SharedForm>
    );
  }
}
