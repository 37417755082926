import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import './index.css';
import { Query } from 'react-apollo';
import { GET_CURRENT_USER } from '../../api/user';

export default function Report({
  report: {
    _id,
    reportNum,
    reason,
    createdAt,
    handled,
    relatedMsg,
    relatedCom,
    targetUser,
    type,
    entity,
  },
  handleReport,
}) {
  const target = relatedMsg ||
    relatedCom ||
    JSON.parse(entity) || {
      content: (
        <p style={{ color: '#D80F15' }}>*The Content is already deleted!*</p>
      ),
    };
  if (!handled && target.deleted) return '';
  reportNum += 1;
  return (
    <div className="report-container">
      <div className="report-container-left">
        <div className="report-container-details">
          <div className="report-container-content">
            <b>
              {target.deleted && <span className="report-deleted">[消息已删除]</span>}
              {type === 'message' ? '纸条' : '评论'}：
            </b>
            {target.content}
          </div>
          {((target || {}).imgUrls || []).map(
            (imgUrl, index) =>
              imgUrl && (
                <a
                  href={`https://cdn.zhitiaox.com/${imgUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <img
                    className="report-container-image"
                    src={`https://cdn.zhitiaox.com/${imgUrl}`}
                    alt="Not loaded"
                    style={{ border: '1px solid rgb(0,0,0,0.2)' }}
                  />
                </a>
              )
          )}

          {target.imgUrl && (
            <a
              href={`https://cdn.zhitiaox.com/${target.imgUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="report-container-image"
                src={`https://cdn.zhitiaox.com/${target.imgUrl}`}
                alt="Not loaded"
                style={{ border: '1px solid rgb(0,0,0,0.2)' }}
              />
            </a>
          )}
        </div>
        <div className="report-container-separator" />

        <div
          className="report-container-details"
          style={{ padding: '7px 15px 10px' }}
        >
          <div className="report-container-details-divider">
            <div className="report-container-details-field">
              <div className="report-container-details-title">
                User:
              </div>
              <Query query={GET_CURRENT_USER}>
                {({ data: { currentUser } }) => {
                  if (currentUser.role === 'admin') return (
                    <>
                      <img alt="avatar" src={(targetUser || {}).avatarUrl} />
                      {(targetUser || {}).nickname}
                    </>
                  );
                  return  (
                    target.isAnom ?
                      <>
                        <img alt="avatar" src={(targetUser || {}).gender === 'male' ?
                          'https://cdn.zhitiaox.com/avatar/male.png' :
                          'https://cdn.zhitiaox.com/avatar/female.png'
                        } />
                        {(targetUser || {}).gender === 'male' ? '某男生' : '某女生'}
                      </> :
                      <>
                        <img alt="avatar" src={(targetUser || {}).avatarUrl} />
                        {(targetUser || {}).nickname}
                      </>
                  );
                }}
              </Query>
            </div>
            <div className="report-container-details-field">
              <div className="report-container-details-title">Reason: </div>
              {reason}
            </div>
            <div className="report-container-details-field">
              <div className="report-container-details-title">Time: </div>
              {dayjs(createdAt).format('YY-MM-DD HH:mm')}
            </div>
            <div className="report-container-details-field">
              被<b>{reportNum}</b>位用户举报
            </div>
          </div>
        </div>
      </div>
      <div
        className="report-container-buttons"
        style={{ color: handled ? 'rgb(68, 179, 146)' : '#D80F15' }}
      >
        {handled ? 'Handled' : 'Pending'}
      </div>
    </div>
  );
}

Report.propTypes = {
  report: PropTypes.shape({
    _id: PropTypes.string,
    reason: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};
