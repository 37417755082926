import { gql } from 'apollo-boost';

export const GET_REPORTS = gql`
  query reports(
    $pageSize: Int!
    $currentPage: Int!
    $searchTerm: SearchReportFilter
    $orderBy: Int
  ) {
    reports(
      pageSize: $pageSize
      currentPage: $currentPage
      searchTerm: $searchTerm
      orderBy: $orderBy
    ) {
      _id
      handled
      reason
      entity
      relatedMsg {
        _id
        content
        imgUrls
        createdAt
        deleted
        isAnom
      }
      relatedCom {
        _id
        content
        imgUrl
        createdAt
        deleted
        isAnom
      }
      reportNum
      type
      targetUser {
        _id
        nickname
        avatarUrl
        gender
      }
      createdAt
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($id: String!, $handled: Boolean!) {
    updateReport(_id: $id, handled: $handled) {
      _id
      handled
    }
  }
`;
