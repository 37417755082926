import React, { Component, Fragment } from 'react';
import { Select, message, Empty } from 'antd';
import ApplicationCard from '../../components/ApplicationCard';
import ApplicationDetails from '../../components/ApplicationDetails';
import ApplicationForm from '../../components/ApplicationDetails/form';
import { Query, withApollo } from 'react-apollo';
import {
  GET_APPLICATIONS,
  AUTHORIZE_APPLICATION,
  REJECT_APPLICATION
} from '../../api/application';
import ModalBox from '../../components/ModalBox';
import Loading from '../../components/Loading';
import './index.css';

const { Option } = Select;

class ApplicationList extends Component {
  state = {
    applicationType: 'all',
    statusType: 'pending',
    currentApplicationId: '',
    currentApplication: {},
    currentOperation: ''
  };

  _handleAuthorize = () => {
    const {
      currentApplication: { _id },
      applicationType,
      statusType
    } = this.state;
    this.props.client
      .mutate({
        mutation: AUTHORIZE_APPLICATION,
        variables: { _id },
        refetchQueries: () => [
          {
            query: GET_APPLICATIONS,
            variables: {
              searchTerm: {
                ...(applicationType !== 'all' && { type: applicationType }),
                ...(statusType !== 'all' && { status: statusType })
              }
            }
          }
        ]
      })
      .then(() => message.success('Application authorized!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
  };

  _handleReject = rejectReason => {
    const {
      currentApplication: { _id },
      applicationType,
      statusType
    } = this.state;
    this.props.client
      .mutate({
        mutation: REJECT_APPLICATION,
        variables: { _id, rejectReason },
        refetchQueries: () => [
          {
            query: GET_APPLICATIONS,
            variables: {
              searchTerm: {
                ...(applicationType !== 'all' && { type: applicationType }),
                ...(statusType !== 'all' && { status: statusType })
              }
            }
          }
        ]
      })
      .then(() => message.success('Application rejected!'))
      .catch(err => message.error(err.message || 'Error occurred!'));
  };

  _handleConfirm = () => {
    const { form } = this.formRef.props;
    if (!form) return this._closeModal();
    form.validateFields((err, values) => {
      if (err) return;
      const { _id, reason } = values || {};
      if (_id) {
        //Authorizing application
        if (_id === this.state.currentApplicationId) {
          //Authentication
          this._handleAuthorize();
        } else message.error('ID is not correct!');
      } else if (reason) {
        //Rejecting application
        this._handleReject(reason);
      }
    });
    this._closeModal();
  };

  _handleOperation = type => {
    this.setState({
      currentOperation: type
    });
  };

  _closeModal = () => {
    this.setState({
      currentApplicationId: '',
      currentApplication: {},
      currentOperation: ''
    });
  };

  render() {
    const {
      applicationType,
      statusType,
      currentApplicationId,
      currentOperation
    } = this.state;

    return (
      <div>
        <Select
          style={{ width: '150px', marginRight: '8px' }}
          onChange={type => this.setState({ applicationType: type })}
          defaultValue={applicationType}
        >
          <Option value="all">All applications</Option>
          <Option value="carton">Carton</Option>
          <Option value="identity">Identification</Option>
        </Select>

        <Select
          style={{ width: '150px', marginRight: '8px' }}
          onChange={type => this.setState({ statusType: type })}
          defaultValue={statusType}
        >
          <Option value="all">Any status</Option>
          <Option value="authorized">Authorized</Option>
          <Option value="pending">Pending</Option>
          <Option value="rejected">Rejected</Option>
        </Select>
        <Query
          query={GET_APPLICATIONS}
          variables={{
            searchTerm: {
              ...(applicationType !== 'all' && { type: applicationType }),
              ...(statusType !== 'all' && { status: statusType })
            }
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <p>Error!</p>;
            const { applications = {} } = data;

            return applications.length ? (
              <Fragment>
                <p style={{ margin: '10px 0' }}>
                  Showing {applications.length} applications
                </p>
                <div className="application-list-container">
                  {applications.map(application => (
                    <ApplicationCard
                      key={application._id}
                      application={application}
                      onClick={() =>
                        this.setState({
                          currentApplicationId: application._id,
                          currentApplication: application
                        })
                      }
                    />
                  ))}
                </div>
              </Fragment>
            ) : (
              <Empty className="empty-hint" />
            );
          }}
        </Query>
        <ModalBox
          visible={!!currentApplicationId}
          title={`Viewing application (id: ${currentApplicationId})`}
          onConfirm={() => {
            if (currentOperation) this._handleConfirm();
          }}
          onCancel={this._closeModal}
        >
          <ApplicationDetails
            _id={currentApplicationId}
            _handleOperation={this._handleOperation}
          />
          <ApplicationForm
            application={currentApplicationId}
            operation={currentOperation}
            onSetRef={ref => {
              this.formRef = ref;
            }}
            onSubmit={this._handleConfirm}
          />
        </ModalBox>
      </div>
    );
  }
}

export default withApollo(ApplicationList);
