import React from 'react';
import { Query } from 'react-apollo';
import SharedForm from '../../components/SharedForm';
import Loading from '../../components/Loading';
import { Input, Select, InputNumber, Checkbox } from 'antd';
import { GET_MESSAGE_DETAIL } from '../../api/message';

const { Option } = Select;

export default function MessageForm({
  onSubmit,
  onSetRef,
  _id,
  operationType
}) {
  if (!_id) return null;

  return (
    <Query query={GET_MESSAGE_DETAIL} variables={{ _id }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error!</p>;
        const { carton, content, isAnom, hotFactor = 0 } = data.message || {};

        return operationType !== 'Edit' ? (
          <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
            {{
              formItems: [
                {
                  title: 'Reason',
                  name: 'reason',
                  options: {
                    rules: [
                      {
                        required: true,
                        message: 'Please choose the reason for deletion!'
                      }
                    ]
                  },
                  render: () => (
                    <Select style={{ width: '100%' }}>
                      <Option value="垃圾广告">垃圾广告</Option>
                      <Option value="违法信息">违法信息</Option>
                      <Option value="色情低俗">色情低俗</Option>
                      <Option value="键盘政治">键盘政治</Option>
                      <Option value="人身攻击">人身攻击</Option>
                      <Option value="不实信息">不实信息</Option>
                      <Option value="学术不端">学术不端</Option>
                      <Option value="带节奏/引战">带节奏/引战</Option>
                    </Select>
                  )
                }
              ]
            }}
          </SharedForm>
        ) : (
          <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
            {{
              formItems: [
                {
                  title: 'Content',
                  name: 'content',
                  options: {
                    initialValue: content || '',
                    rules: [
                      {
                        required: !!content,
                        message: 'Content cannot be empty'
                      }
                    ]
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Carton ID',
                  name: 'carton',
                  options: {
                    initialValue: (carton || {})._id
                  },
                  render: () => <Input style={{ width: '100%' }} />
                },
                {
                  title: 'Hot factor',
                  name: 'hotFactor',
                  options: {
                    initialValue: hotFactor || 0,
                    rules: [
                      {
                        required: !!hotFactor,
                        message: 'Please input hot factor!'
                      }
                    ]
                  },
                  render: () => <InputNumber style={{ width: '90%' }} />
                },
                {
                  title: 'Show Identity',
                  name: 'isAnom',
                  options: {
                    initialValue: isAnom ? true : false,
                    rules: [{ required: true, message: 'Please choose one' }]
                  },
                  render: () => <Checkbox />
                }
              ]
            }}
          </SharedForm>
        );
      }}
    </Query>
  );
}
