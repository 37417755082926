import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import App from './App';
import { ROOT_URL } from './config';
import { GET_TOKEN } from './api/user';
import { defaultOptions, resolvers } from './apollo';
import * as serviceWorker from './serviceWorker';
import './index.css';

const cache = new InMemoryCache();

persistCache({
  cache,
  storage: window.localStorage
});

const client = new ApolloClient({
  uri: ROOT_URL,
  cache,
  resolvers,
  onError: ({ graphQLErrors, networkError }) => {
    if (
      graphQLErrors &&
      graphQLErrors.some(({ name }) => name === 'AuthError')
    ) {
      cache.writeData({
        data: {
          currentUser: null,
          token: null
        }
      });
    }
  },
  request: operation => {
    try {
      const { token } = cache.readQuery({
        query: GET_TOKEN
      });
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : ''
        }
      });
    } catch (err) {}
  }
});

client.defaultOptions = defaultOptions;

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}
