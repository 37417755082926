import React from 'react';
import SharedForm from '../../components/SharedForm';
import { Input, Select } from 'antd';
const { Option } = Select;

export default function CartonForm({ onSubmit, onSetRef }) {
  return (
    <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
      {{
        formItems: [
          {
            title: '用户',
            name: 'user',
            options: {
              initialValue: '某同学'
            },
            render: () => (
              <Select onChange={value => console.log(value)}>
                <Option key="某同学">某同学</Option>
                <Option key="某男生">某男生</Option>
                <Option key="某女生">某女生</Option>
              </Select>
            )
          },
          {
            title: '内容',
            name: 'content',
            options: {
              initialValue: '',
              rules: [
                { required: true, message: 'Content cannot be empty' }
              ]
            },
            render: () => <Input.TextArea style={{ width: '100%', minHeight: '120px' }} />
          }
        ]
      }}
    </SharedForm>
  );
}
