import React from 'react';
import SharedForm from '../../components/SharedForm';
import { Input, Select, InputNumber } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

export default function BannerForm({ onSubmit, onSetRef, banner }) {
  const { url, type, imgUrl, content, desc, carton, topic, school, priority } =
    banner || {};

  return (
    <SharedForm wrappedComponentRef={onSetRef} onSubmit={onSubmit}>
      {{
        formItems: [
          {
            title: 'Type',
            name: 'type',
            options: {
              initialValue: type,
              rules: [{ required: true, message: 'Please input banner type!' }],
            },
            render: () => (
              <Select>
                <Option value="carousel">carousel</Option>
                <Option value="banner">banner</Option>
                <Option value="article">article</Option>
              </Select>
            ),
          },
          {
            title: 'Url',
            name: 'url',
            options: {
              initialValue: url,
              rules: [{ required: false }],
            },
            render: () => <Input />,
          },
          {
            title: 'Image Url',
            name: 'imgUrl',
            options: {
              initialValue: imgUrl,
              rules: [{ required: false }],
            },
            render: () => <Input />,
          },
          {
            title: 'Content',
            name: 'content',
            options: {
              initialValue: content,
              rules: [
                {
                  required: false,
                },
              ],
            },
            render: () => <TextArea />,
          },
          {
            title: 'Description',
            name: 'desc',
            options: {
              initialValue: desc,
              rules: [
                {
                  required: false,
                },
              ],
            },
            render: () => <Input />,
          },
          {
            title: 'Carton ID',
            name: 'carton',
            options: {
              initialValue: carton ? carton._id : null,
              normalize: (value, prevValue, prevValues) => value || null,
            },
            render: () => <Input />,
          },
          {
            title: 'Topic',
            name: 'topic',
            options: {
              initialValue: topic,
            },
            render: () => <Input />,
          },
          {
            title: 'School',
            name: 'school',
            options: {
              initialValue: school,
              rules: [{ required: false }],
            },
            render: () => <Input />,
          },
          {
            title: 'Priority',
            name: 'priority',
            options: {
              initialValue: priority,
              rules: [
                { required: true, message: 'Please input banner priority!' },
              ],
            },
            render: () => <InputNumber />,
          },
        ],
      }}
    </SharedForm>
  );
}
