import React, { useState } from 'react';
import { Query, withApollo } from 'react-apollo';
import dayjs from 'dayjs';
import DayPicker from 'react-day-picker';
import { GET_USER_DETAIL } from '../../api/user';
import 'react-day-picker/lib/style.css';
import Loading from '../Loading';
import './index.css';

function UserDetail({ _id, type, _handleUpdate }) {
  let [dateValue, setDateValue] = useState('');
  let [oldId, setID] = useState('');

  if (!_id) return null;
  if (!type) return null;

  if (!oldId || oldId !== _id) {
    setDateValue('');
    setID(_id);
  }

  const handleDateClick = day => {
    _handleUpdate('selectedDate', day);
    setDateValue(day);
  };
  return (
    <Query query={GET_USER_DETAIL} variables={{ _id, type, _handleUpdate }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error!</p>;
        const {
          createdAt,
          updatedAt,
          avatarUrl,
          nickname,
          gender,
          celebrity,
          grade,
          major,
          school,
          identity,
          phone,
          followers = [],
          following = [],
          cartons = [],
          agreeTerms,
        } = data.user || {};
        return (
          <div className="user-details-container">
            <div className="user-details-top-dates">
              <div>
                <span style={{ fontWeight: 'bold', color: '#2B9EB3' }}>
                  Created at:
                </span>{' '}
                {dayjs(createdAt).format('YYYY-MM-DD')}
              </div>
              <div>
                <span style={{ fontWeight: 'bold', color: '#2B9EB3' }}>
                  Last active:
                </span>{' '}
                {dayjs(updatedAt).format('YYYY-MM-DD')}
              </div>
            </div>
            <div className="user-details-top-container">
              <img className="user-details-top-avatar" src={avatarUrl} alt="" />
              <div className="user-details-top-details">
                <div style={{ fontWeight: 'bold' }}>
                  {nickname}
                  <div
                    className="user-details-bubble"
                    style={{
                      border: '0px',
                      color: gender === 'male' ? '#1C5D99' : '#C71453',
                      fontWeight: 'bold',
                      marginRight: '0px',
                    }}
                  >
                    {gender}
                  </div>
                  <div
                    className="user-details-bubble"
                    style={{
                      border: '0px',
                      color: '#d68904',
                      fontWeight: 'bold',
                      opacity: celebrity ? '1' : '0',
                    }}
                  >
                    *{celebrity || ''}*
                  </div>
                </div>
                <div className="user-details-bubble-container">
                  <div className="user-details-bubble">
                    {grade || 'no grade'}
                  </div>
                  <div className="user-details-bubble">
                    {major || 'no major'}
                  </div>
                  <div
                    className="user-details-bubble"
                    style={{ color: '#724FCC', borderColor: '#724FCC' }}
                  >
                    {school || 'no school'}
                  </div>
                </div>
              </div>
            </div>

            <div className="user-details-below-container">
              {identity ? (
                <div className="user-identity-container">
                  <div className="user-identity-container-school">
                    {identity.school}
                  </div>
                  <div className="user-identity-container-Id">
                    {identity.studentId}
                  </div>
                  <div className="user-identity-container-Id">
                    {phone}
                  </div>
                </div>
              ) : (
                <div className="user-noIdentity-container">
                  <div className="user-noIdentity">
                    The user's identity is not verified!
                  </div>
                </div>
              )}
            </div>
            {type === 'View' && (
              <div className="user-details-below-container">
                <div className="user-details-counter-container">
                  <div className="user-details-counter-title">
                    Followers ({followers.length})
                  </div>
                  {followers.slice(0, 8).map((follower, index) => (
                    <img
                      className="user-details-counter-avatar"
                      key={index}
                      src={follower.avatarUrl}
                      alt=""
                    />
                  ))}
                </div>

                <div className="user-details-counter-container">
                  <div className="user-details-counter-title">
                    Following users ({following.length})
                  </div>
                  {following.slice(0, 8).map((idol, index) => (
                    <img
                      className="user-details-counter-avatar"
                      key={index}
                      src={idol.avatarUrl}
                      alt=""
                    />
                  ))}
                </div>

                <div className="user-details-counter-container">
                  <div className="user-details-counter-title">
                    Following cartons ({cartons.length})
                  </div>
                  {cartons.slice(0, 8).map((carton, index) => (
                    <img
                      className="user-details-counter-avatar"
                      key={index}
                      src={carton.imgUrl}
                      alt=""
                    />
                  ))}
                </div>
                {agreeTerms ? (
                  <div className="user-details-unban-message">
                    {' '}
                    *User has agreed to the terms and services*
                  </div>
                ) : (
                  <div className="user-details-ban-message">
                    {' '}
                    *User has NOT agreed to the terms and services*
                  </div>
                )}
              </div>
            )}

            {type === 'Ban' &&
              ('banned' ? (
                <div className="user-details-unban-message">
                  {' '}
                  *Are you sure you want to unban {nickname}?*
                </div>
              ) : (
                <div className="user-details-ban-message">
                  {' '}
                  *Are you sure you want to ban {nickname}?*
                  <DayPicker
                    onDayClick={handleDateClick}
                    selectedDays={dateValue || new Date()}
                    className="user-details-calendar"
                  />
                </div>
              ))}
          </div>
        );
      }}
    </Query>
  );
}

export default withApollo(UserDetail);
