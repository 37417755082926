import { gql } from "apollo-boost";

export const GET_MESSAGES = gql`
  query messages(
    $pageSize: Int!
    $currentPage: Int!
    $searchTerm: SearchMessageFilter
  ) {
    messages(
      pageSize: $pageSize
      currentPage: $currentPage
      searchTerm: $searchTerm
    ) {
      _id
      content
      imgUrls
      isAnom
      user {
        nickname
        gender
        avatarUrl
      }
      deleted
      createdAt
      hotFactor
      carton {
        _id
        title
        desc
        imgUrl
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($data: CreateMessageInput!) {
    createMessage(data: $data) {
      _id
    }
  }
`;

export const GET_MESSAGE_DETAIL = gql`
  query message($_id: ID!) {
    message(_id: $_id) {
      imgUrls
      likeNum
      user {
        _id
        nickname
        avatarUrl
        gender
      }
      content
      createdAt
      hotFactor
      isAnom
      comments {
        _id
        content
        imgUrl
        isAnom
        deleted
        user {
          _id
          nickname
          gender
          avatarUrl
        }
      }
      carton {
        _id
        title
        desc
        imgUrl
      }
    }
  }
`;

export const DELETE_MSG = gql`
  mutation deleteMessage($_id: ID!) {
    deleteMessage(_id: $_id) {
      user {
        nickname
      }
      content
    }
  }
`;

export const UPDATE_MESSAGE_DETAIL = gql`
  mutation UpdateMessge($_id: ID!, $data: CreateMessageInput!) {
    updateMessage(_id: $_id, data: $data) {
      _id
    }
  }
`;
