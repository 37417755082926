import React from 'react';
import { Modal } from 'antd';

export default function ModalBox({
  children,
  title,
  visible,
  onConfirm,
  onCancel,
  hideFooter
}) {
  return (
    <div>
      {
        hideFooter ?
          <Modal
            title={title}
            centered
            visible={visible}
            onOk={onConfirm}
            onCancel={onCancel}
            footer={null}
          >
            {children}
          </Modal> :
          <Modal
            title={title}
            centered
            visible={visible}
            onOk={onConfirm}
            onCancel={onCancel}
          >
            {children}
          </Modal>
      }
    </div>
  );
}
