export default {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'none'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none'
  },
  mutate: {
    errorPolicy: 'none'
  }
};
