import React, { Component } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import './index.css';

class SharedForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { formItems = [], submitButton } = this.props.children || {};
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} style={this.props.style}>
        {formItems.map((item, key) =>
          <Form.Item
            key={key}
            label={item.title}
            {...(item.layout ? item.layout : formItemLayout)}
          >
            {getFieldDecorator(item.name, item.options)(item.render())}
          </Form.Item>
        )}
        {submitButton && submitButton.render()}
      </Form>
    );
  }
}

SharedForm.propTypes = {
  children: PropTypes.shape({
    formItems: PropTypes.array.isRequired,
    submitButton: PropTypes.object
  }),
  onSubmit: PropTypes.func.isRequired
};

export default Form.create()(SharedForm);
