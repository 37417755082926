import React from 'react';
import { Layout, message } from 'antd';
import { withApollo } from 'react-apollo';
import AuthForm from './AuthForm';
import NavBar from '../../components/NavBar';
import { LOGIN_USER, SET_USER } from '../../api/user';
import './index.css';

const { Footer } = Layout;

function LoginPage({ client }) {
  const authUser = ({ username, password } = {}) => {
    client.mutate({
        mutation: LOGIN_USER,
        variables: { username, password }
      })
      .then(({ data }) => {
        if (!data || !data.login) throw new Error('Invalid credentials!')
        client.mutate({
          mutation: SET_USER,
          variables: {
            user: data.login.user,
            token: data.login.token
          }
        });
        message.success('User successfully logged in!');
      })
      .catch((err) => message.error(err.message || err.message || 'Error occurred!'));
  };

  return (
    <div className="login-screen">
      <NavBar />
      <div className="page-body">
        <div className="form-container">
          <h1>Manager Panel</h1>
          <AuthForm onSubmit={authUser} />
        </div>
      </div>
      <Footer className="footer">纸条 ©2019 Created with Love</Footer>
    </div>
  );
}

export default withApollo(LoginPage);
