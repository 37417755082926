/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { Query, withApollo } from 'react-apollo';
import { GET_MESSAGE_DETAIL } from '../../api/message';
import { GET_CURRENT_USER } from '../../api/user';
import { DELETE_COM } from '../../api/comment';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading';
import UserDetail from '../UserDetail';
import ModalBox from '../ModalBox';
import './index.css';

function messageDetail({ client, _id }) {
  if (!_id) return null;
  const [showUserModal, toggleUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');

  const toggleCommentUser = (willOpen, userId) => {
    toggleUserModal(willOpen);
    setSelectedUserId(willOpen ? userId : '');
  };

  const deleteCom = _id => {
    console.log(_id);
    client.mutate({
      mutation: DELETE_COM,
      variables: { _id }
    }).then(()=> message.success('Comment Deleted!'))
    .catch(err =>  message.error(err.message || 'Error occurred!'));
  };

  const commentsSection = (comments, enableUserToggle) => {
    if (!comments.length) return null;
    return (
      <div className="message-detail-comments">
        {comments.map((comment, index) => (
          <div key={comment._id}>
            <div className="message-comment-container">
              <img
                className="message-detail-user-avatar"
                src={comment.user.avatarUrl}
                alt=""
                style={{
                  borderColor:
                    comment.user.gender === 'male' ? '#1C5D99' : '#C71453',
                }}
                onClick={() => enableUserToggle ? toggleCommentUser(true, comment.user._id) : null}
              />
              <div className="message-detail-comment">
                {comment.deleted && <span className="comment-deleted">[评论已删除]</span>}
                {comment.content}
                {comment.imgUrl && (
                  <a
                    href={`https://cdn.zhitiaox.com/${comment.imgUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://cdn.zhitiaox.com/${comment.imgUrl}`}
                      className="message-detail-img"
                      style={{
                        display: comment.imgUrl ? 'block' : 'none',
                      }}
                      alt=""
                    />
                  </a>
                )}
              </div>
              {!comment.deleted && 
              <Popconfirm
                title="Delete?"
                onConfirm={() => deleteCom(comment._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                    shape="circle"
                    type="danger"
                    icon="delete"
                    className="message-button"
                />
              </Popconfirm>
               }
            </div>
          </div>
        ))}
      </div>
    );
  };

  const UserModal = (
    <ModalBox
      visible={showUserModal}
      title={`View user (id: ${selectedUserId})`}
      onConfirm={() => toggleCommentUser(false)}
      onCancel={() => toggleCommentUser(false)}
    >
      <UserDetail _id={selectedUserId} type="View" />
    </ModalBox>
  );

  return (
    <Query query={GET_MESSAGE_DETAIL} variables={{ _id }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error!</p>;
        const {
          user,
          content,
          comments,
          likeNum,
          imgUrls,
          carton,
        } = data.message;

        const { _id, nickname, avatarUrl, gender } = user || {};
        return (
          <div className="message-detail-container">
            <div className="message-detail-user"
            onClick={() => toggleCommentUser(true, _id)}
            >
              <img
                className="message-detail-user-avatar"
                src={avatarUrl}
                alt=""
                style={{
                  borderColor: gender === 'male' ? '#1C5D99' : '#C71453',
                }}
              />
              <div
                className="message-detail-user-details"
                style={{ color: gender === 'male' ? '#1C5D99' : '#C71453' }}
              >
                <div className="message-detail-user-nickname">{nickname}</div>
                <div className="message-detail-user-id">user ID: {_id}</div>
              </div>
            </div>
            <div className="message-detail-content">
              <div className="message-detail-content-text">{content}</div>
              {/** Images */}
              {(imgUrls || {}).map(
                (imgUrl, index) =>
                  imgUrl && (
                    <a
                      href={`https://cdn.zhitiaox.com/${imgUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <img
                        className="message-detail-img"
                        src={`https://cdn.zhitiaox.com/${imgUrl}`}
                        alt=""
                      />
                    </a>
                  )
              )}

              {/** Overview */}
              <div className="message-detail-content-stats">
                *This message has {likeNum} likes and {comments.length}{' '}
                comments!*
              </div>

              {/** Carton */}
              {carton && (
                <div className="message-detail-carton">
                  <img
                    className="message-detail-carton-img"
                    src={carton.imgUrl}
                    alt=""
                  />
                  <div className="message-detail-carton-details">
                    <div className="message-detail-carton-title">
                      {carton.title}
                    </div>
                    <div className="message-detail-carton-desc">
                      {carton.desc}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/** Comments */}
            
            <Query query={GET_CURRENT_USER}>
              {({ data: { currentUser } }) => {
                if (currentUser.role === 'admin') return commentsSection(comments, true);
                return commentsSection(comments, false);
              }}
            </Query>
            
            {UserModal}
          </div>
        );
      }}
    </Query>
  );
}

export default withApollo(withRouter(messageDetail));
